<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="$router.push({name: 'user'})" />
            </div>
          </template>
        </Toolbar>

        <DataTable
            ref="dt" :value="users" dataKey="id"
            :paginator="true"
            :lazy="true"
            :total-records="totalRecords"
            :loading="loading"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5,10,25]"
            :rows="10"
            currentPageReportTemplate="Total {totalRecords} produtos"
            responsiveLayout="scroll"
            @page="findAll($event)"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Gerenciamento de Usuários</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText @change="findAll(page)" v-model="pesquisar" placeholder="Pesquisar..." />
                            </span>
            </div>
          </template>
          <Column field="name" header="Nome"></Column>
          <Column field="celular" header="Celular"></Column>
          <Column header="Permissões">
            <template #body="slotProps">
              <span v-for="role in slotProps.data.roles" v-bind:key="role.id" class="p-badge p-badge-info mr-1">{{role.customName}}</span>
            </template>
          </Column>
          <Column header="Ativado?">
            <template #body="slotProps">
              <InputSwitch v-model="slotProps.data.active" @change="update(slotProps.data)"></InputSwitch>
            </template>
          </Column>
          <Column :exportable="false" style="min-width:8rem" header="Ações">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="redirectEdit(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="user">Tem certeza de que deseja excluir <b>{{user.name}}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="remove" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/service/UserService";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "users",
  created() {
    this.userService = new UserService();
    this.initFilters();
  },
  async mounted() {
    this.loading = true;
    await this.findAll(this.page);
    this.loading = false;
  },
  data() {
    return {
      userService: null,
      users: [],
      user: {},
      filters: [],
      deleteDialog: false,
      loading: false,
      totalRecords: 0,
      pageSize: 0,
      pesquisar: null,
      page: {
        originalEvent: {
          rows: 10,
          page: 0
        }
      }
    }
  },
  methods: {

    async findAll(event) {
      let response = null;
      this.skeletor = true;

      if(this.pesquisar) {
        response = await this.userService.findAll(event.originalEvent.rows, event.originalEvent.page, this.pesquisar);
      } else {
        response = await this.userService.findAll(event.originalEvent.rows, event.originalEvent.page);
      }
      if(response.status === 200) {
        this.totalRecords = response.data.totalElements;
        this.users = response.data.content;
      }
      this.skeletor = false;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    redirectEdit(user) {
      this.$router.push({name: "user", query: {id: user.id}});
    },
    async update(user) {
      this.userService.save(user);
    },
    confirmDelete(user) {
      this.user = user;
      this.deleteDialog = true;
    },
    async remove() {
      this.loading = true;
      try {
        const response = await this.userService.delete(this.user.id);
        if (response.status === 200) {
          this.deleteDialog = false;
          this.product = {};
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Usuário removido com sucesso.',
            life: 3000
          });
          await this.findAll(this.page);
        }
      } catch(e) {
        this.deleteDialog = false;
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Usúario não pode ser removido, pois possuí vinculo com outros registros,',
          life: 4000
        });
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>
